module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://8ae484dbc0204f15aa80e96feaf6f97a@o274114.ingest.sentry.io/1489466"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Migration in Harmony","short_name":"Migration Harmony","description":"Migration in Harmony is a Research Coordination Network on Arctic migrations funded by the National Science Foundation.","start_url":"/?utm_source=a2hs","background_color":"#147CED","theme_color":"#147CED","display":"standalone","icon":"src/images/logo-blue.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"position":"fixed","top":0,"left":0,"right":0,"bottom":0,"backgroundColor":"rgba(0, 0, 0, 0.75)"},"content":{"position":"absolute","border":"none","background":"none","padding":0,"top":0,"bottom":0,"right":0,"left":0,"overflow":"auto","WebkitOverflowScrolling":"touch"}},"contentLabel":"Modal"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
