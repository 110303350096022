/* eslint-disable prefer-destructuring */
const breakpoints = ['576px', '768px', '992px', '1300px']

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export default {
  breakpoints,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body:
      'Futura, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    text: {
      heading: 'Futura Heavy',
      p: 'Futura Book Regular',
    },
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96],
  fontFamily: {
    medium: 'Futura Bold',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: '#1D1D1D',
    background: '#fff',
    white: '#FFFFFF',
    black: '#1D1D1D',
    grey: '#434343',
    primary: {
      one: '#147CED',
      two: '#007AE8',
      three: '#003AA0',
    },
    lightBlue: '#EFF7FF',
    input: '#F4F4F4',
    secondary: '#30c',
    muted: '#f6f6f6',
  },
  buttons: {
    primary: {
      bg: 'primary.one',
      fontFamily: 'Futura Bold',
      fontSize: 20,
      cursor: 'pointer',
    },
    secondary: {
      bg: 'background',
      color: 'primary.one',
      fontFamily: 'Futura Bold',
      fontSize: 16,
      cursor: 'pointer',
      '&:disabled': {
        cursor: 'not-allowed',
      },
      '&:hover': {
        opactiy: 0.8,
      },
    },
  },
  text: {
    heading: {
      fontFamily: 'text.heading',
      lrg: {
        fontFamily: 'text.heading',
        fontSize: 6,
      },
      med: {
        fontFamily: 'text.heading',
        fontSize: 5,
      },
      sml: {
        fontFamily: 'text.heading',
        fontSize: 4,
      },
    },
    p: {
      fontFamily: 'text.p',
      color: 'grey',
      fontSize: 3,
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
    },
    h2: {
      color: 'text',
      fontFamily: 'Futura Bold',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
    },
    h3: {
      color: 'text',
      fontFamily: 'Futura Bold',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2,
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
    },
  },
  links: {
    social: {
      color: 'text',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
}
